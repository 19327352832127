.subscribe-container {

    
    min-height:180px;
    
   /*  background-image: url('./newsletterbck.jpg');
    background-position: center;
    background-size: cover;
    margin-top:10%;
     */
    color:var(--main-font-color);
}



.subscribe-form {

    max-width:1440px;    
    margin:auto;

}

.newsletter-title {

    
    font-size:24px;
    font-weight:800;
    text-align:center;
    text-transform:uppercase;
    margin:10px;

}

.input-fields {
    display:flex;
    justify-content:center;
    margin:40px;
    font-weight:700;
}

.input-field {

   margin:10px;
   color:var(--main-font-color) !important;
 
}


#mce-LNAME-label {

    color:var(--main-font-color) !important;
    font-weight:700 !important;
 
}

#mce-FNAME-label {

    color:var(--main-font-color) !important;
    font-weight:700 !important;

}


#mce-EMAIL-label {

    color:var(--main-font-color) !important;
    font-weight:700 !important;
    border-color:black !important;
}

.newsletter-color-filter {

    background-color:var(--subscribe-color);
   /*  padding:12px; */
}




#mc_embed_signup {
    
    clear: left;
    font: 14px;
    max-width: 600px;
    background-color:var(--container-color);
    margin:auto;
    padding:30px;
   
    
    background-opacity:0.2;
}



.mailchimp-button {

    margin: 10px auto 10px auto;
    font-size: 14px;
    padding: 6px 12px 6px 12px;
    max-width:120px;
    font-weight: 600;
    text-transform: uppercase;
    color: white;
    background-color: #1e1e1e;
    transition: 0.2s ease-in;
    cursor:pointer;
}


.mailchimp-button:hover {

    transition: 0.5s ease-in;
      background-color: #626161;
    }




    #mc_embed_signup h2
{
  font-weight: 800;
  text-transform:uppercase;
}

.mc-checkbox {

    display:flex !important;
    flex-direction:row !important;
    margin:auto;
    justify-content:center;

}
.mc-checkbox label {

width:90%;

}

.mc-checkbox input {

    width:10% !important;
    margin: auto;
    }