.slide {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.infocus {

  color:var(--focus);

}
.slide.animate {
  opacity: 1;
  transform: translateY(0);
}

.main-titles-background {
  padding-top:20px;
  padding-bottom:20px;
  width:100%;
  background-image:var(--pattern);
}

.main-titles {
  background-color: var(--container-color);
    text-transform: uppercase;
    font-weight:600;
    width:300px;
    font-size:2rem;
    padding:1%;
    margin-right:auto;
    margin-left:auto;
    border-color:var(--main-font-color);
    text-align:center;
    color: var(--main-font-color);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transform: skew(-10deg);
     z-index: 1
    
}

.main-titles-sub {
  text-transform: uppercase;
  font-weight:700;
  max-width:100%;
  font-size:6vh;
  padding:6%;
  margin-left:5%;
  border-color:var(--main-font-color);
  text-align:left;
  color: #4a4a4a;
}

.main-titles-sub-h2 {
  border-bottom: 2px solid;
  text-transform: uppercase;
  font-weight:500;
  width:max-content;
  font-size:1rem;
  padding:1% 4% 0.5% 0%;
  margin-left:5%;
  margin-bottom:4%;
  border-color:var(--main-font-color);
  text-align:left;
  color: var(--main-font-color);
}

.inside-container {

  max-width:1550px;
  margin:auto;
}

.separator-sliuett {

  stroke:var(--main-font-color);
  fill:var(--main-font-color);
  height:100px;
  margin:100px auto 100px auto;
}


.button-container {
  display:flex;
  justify-content:center;
}
.book-button {
  min-width:33%;
  border-radius: 1px;
  border: none;
  color: var(--main-font-color);
  font-size: 1rem;
  font-weight:700;
  padding: 10px 40px;
  margin: 10px;
  cursor: pointer;
  border:1px solid;
  border-color:white;
  transition:0.3s;
}

.book-button:hover { 
  border-radius: 4px;
  transition:0.5s;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}



.side-title-back{
  background-image:url('./szobrok.jpg');
  background-size:cover;
  background-position:center;
  height:400px;
  display:flex;
  }
  

  @keyframes slideInFromLeft {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
  }
  
  @keyframes slideInFromRight {
    0% {
        width: 0;
        left: 100%;
    }
    100% {
        width: 100%;
        left: 0;
    }
  }

  .title-text-container {
    width:60%;
    margin:auto;
    padding:2%;
    position: relative;
  }

  .side-title-text {
    //font-size: 6rem;
    font-size: calc(18px + 4vw);
  color: white;
  padding: 2%;
  margin:auto;
  text-transform:uppercase;
  font-weight:800;
  text-align:center;
  }


.title-text-container::before, 
.title-text-container::after {
    content: "";
    position: absolute;
    left: 0;
    height: 5px;
    background: white;
    animation-duration: 1s;
    animation-fill-mode: forwards;  /* Added this line */
}

.title-text-container::before {
    top: 0;
    animation-name: slideInFromLeft;
}

.title-text-container::after {
    bottom: 0;
    animation-name: slideInFromRight;
}



.undertitle {
  font-weight:600;
  max-width:1550px;
  font-size:1rem;
  padding-left:3%;
  margin: 40px auto auto auto;
  text-align:left;
  color: var(--main-font-color);
}


.drop-in {
  animation: drop-in 1s ease 200ms backwards;
 }
 
 .drop-in-2 {
  animation: drop-in 1200ms ease 500ms backwards;
 }
 
 @keyframes drop-in {
  from {
   opacity: 0;
   transform: translateY(-100px);
  }
  to {
   opacity: 1;
   transform: translate(0px);
  }
 }


 .custom-titles {
  font-weight:900;
  color:var(--main-font-color);
  font-size:5rem;
  text-transform: uppercase;
  padding-left:3%;
  max-width:1550px;
  margin:auto;
 }

 @media screen and (max-width: 768px) {
  /* Adjust font size for screens up to 768px wide */
  .custom-titles {
    font-size: calc(2rem + 1vw);
  }
}

@media screen and (max-width: 480px) {
  /* Adjust font size for screens up to 768px wide */
  .custom-titles {
    font-size: calc(2rem + 1vw);
  }
}

.data-policy {

margin:120px auto 80px auto;
max-width:1440px;
}



.data-policy-container {
margin:auto;
}

.data-title {

  font-weight:800;
  font-size: 18px;
  width:100%;
  border-bottom: 2px solid black;
  margin-top:10px;
}


.card-social-icons {

  margin-left:15px;
  background-color:var(--cardsColor);
  height:40px;
  padding:5px;
  width:max-content;
  border-bottom-left-radius:10px;
  border-bottom-right-radius:10px;
  z-index:-1;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  dispay:flex;
  justify-content:center;
  text-align:center;
}

.card-social-icon-mui {

  color:var(--main-font-color);

}

.card-social-icons a {
margin:5px;
transition:0.2s;
}

.card-social-icons a:hover {
  color:grey;
  transition:0.2s;
  }


  .popup-window {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: row; /* Adjust to column layout */
    justify-content: bottom; /* Center vertically */
    align-items: center; /* Center horizontally */
    margin: auto;
    background-color: var(--container-color);
    color: var(--main-font-color);
    padding-bottom:10px;
    outline: none;
    background-image: var(--pattern);
    position:absolute;
    bottom:0;
    left:0;
  }
  

  /* Add this style to center the modal vertically and horizontally */
  .MuiModal-root {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
  }
  

  .popup-open-button {
    
    color: white;
    font-size: 1.2em;
    font-weight: 800;
    padding: 12px 20px;
    margin: 1px;
    cursor: pointer;
    border-color: white;
    transition: 0.3s;
    background-color: #2d2d2d;
    text-transform: uppercase;

  }

  .popup-open-button:hover {

    transition: 0.5s ease-in;
    background-color: #626161;

  }


  .popup-colse-button {
    width:40px;
    height:40px;
    color: white;
    font-size: 1em;
    font-weight: 800;
    cursor: pointer;
    border-color: white;
    transition: 0.3s;
    background-color: red;
    text-transform: uppercase;
    margin: auto;
  }

  .popup-colse-button:hover {

    transition: 0.5s ease-in;
    background-color: #ff5b5b;

  }

  .popup-colse-button-2 {
    width:150px;
    color: white;
    font-size: 0.7em;
    font-weight: 800;
    padding: 6px 10px;
    margin: auto;
    cursor: pointer;
    border-color: white;
    transition: 0.3s;
    background-color: red;
    text-transform: uppercase;
    

  }
  .popup-colse-button-2:hover
    {

      transition: 0.5s ease-in;
    background-color: #ff5b5b;

  }


  #modal-modal-title {

    color: white;
    font-size: 1.2em;
    font-weight: 800;
    padding: 12px 20px;
    margin: 1px;
    border-color: white;
    transition: 0.3s;
    background-color: #2d2d2d;
    text-transform: uppercase;


  }


  #modal-modal-description {

    color: white;
    font-size: 0.9em;
    font-weight: 600;
    padding: 6px 6px;
    margin: 1px;
    transition: 0.3s;
    
    background-color: #2d2d2d;
    text-transform: uppercase;

  }

  .animate-read-more {

    transition:0.3s ease-in;

  }

  .pop-left {

      width:calc(30% - 100px);
      height: 450px;
      padding:10px;
      display:flex;
      justify-content:center;
  }

  .pop-center {
    
    width:calc(70% - 100px);
    height: 200px;
    padding:10px;
    display:flex;
    flex-direction:column;
    justify-content:center;
  }

  .pop-right {
    
    width:200px;
    height: 200px;
    padding:10px;
    display:flex;
    flex-direction:column;
    justify-content:center;
  }

  .popup-image {

      width:100%;
      height:300px;
      overflow:visible;
      object-fit:cover;
      border:2px solid #2d2d2d;
      
  }




  @media screen and (max-width: 768px) {
    .popup-window {
      width:100vw;
      
      flex-direction:column;
      padding:0px;
      position:fixed;
      bottom:0;
      left:0;
    }
    
    .css-79ws1d-MuiModal-root {




    }
  
    /* Add this style to center the modal vertically and horizontally */
    .MuiModal-root {
      max-height:70vh;
      
    }
    
    .popup-open-button {
      font-size:12px;
    }
  
    .popup-colse-button {

    }
  
    .popup-colse-button-2 {
  
    }

  
    #modal-modal-title {
  
      font-size:14px;
      background-color:#2d2d2d;
     
    }
  
    #modal-modal-description {
      font-size:12px;
      background-color:#2d2d2d;

    }
  
    .pop-left {
      width:100vw;
      height:350px;
      padding:0px;
      z-index:1;
      position:fixed;
      bottom:200px;
    }
  
    .pop-center {
      width:100vw;
      padding:0px;

      z-index:99;
      position:fixed;
      bottom:50px;
    }
  
    .pop-right {
      width:100vw;
      height:50px;
      padding:0px;
      z-index:99;
      flex-direction:row;
      position:fixed;
      bottom:0;
    }
  
    .popup-image {
      width:100vw;
      height:100%;
      position:relative;
      bottom:0;
      z-index:1;
      
      border:0;
    }

    .instructor-modal {
      flex-direction:column;
      width: 95vw !important; /* Ensure the modal doesn't exceed the viewport width */
      height:100% !important;/* Ensure the modal doesn't exceed the viewport height */
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
    }
  
    .instructor-sub-side-modal {
      width: 100% !important; /* Adjusted to full width in mobile view */
      padding: 5px;
      
    }
  
    .instructor-main-side-modal {
      width: 90% !important; /* Adjusted to full width in mobile view */
      padding: 5px;
      
    }


   .instructor-sub-side-modal ul {
    
  }

  }



  .instructor-modal {
    display: flex;
    overflow: scroll;
    position: fixed;
    top: 50%; /* Adjusted to center vertically */
    left: 50%; /* Adjusted to center horizontally */
    transform: translate(-50%, -50%); /* Centering trick */
    min-width:70vw;
    max-width: 90vw; /* Ensure the modal doesn't exceed the viewport width */
    max-height: 90vh; /* Ensure the modal doesn't exceed the viewport height */
    background-color: var(--container-color);
    color: var(--main-font-color);
    border-radius:5px;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  .instructor-sub-side-modal {

    width:40%;
    display:flex;
    flex-direction:column;
    justify-content:center;
  }

  .instructor-sub-side-modal img {

    padding:10px;
    border-radius:5px;
    
    
    overflow:hidden;
    object-fit:cover;
  }

  .instructor-sub-side-modal h2 {
    padding:10px;
    font-weight:800;
    font-size:24px;
    
  }

.card-social-icons-modal {

  padding-left:10px;
  
}

  .instructor-sub-side-modal a {
    margin:5px;
    font-weight:800;
    font-size:24px;

  }

  .instructor-sub-side-modal ul {
    display: inline-block;
    list-style: none;
    margin: auto;
    padding: 10px;
    
  }
  .instructor-sub-side-modal ul li{
    background-color: #80808021;
    border-radius: 5px;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 5px 10px;
  }

  .instructor-main-side-modal {
    width:50%;
    display:flex;
    justify-content:center;
    flex-direction:column;
    margin:auto auto auto 20px;
    
  }

  .instructor-main-side-modal p{
    
    margin:10px;
    font-weight:500;
    font-size:15px;
  }

  .modal-description {

    font-weight:600;
    padding: 60px 20px 20px 20px;
    margin:auto;
    
  }

  .modal-price {

    background-color:grey;
    padding:10px;
    margin:auto auto 20px 10px;

    color:white;
    font-weight:700;
    text-transform:uppercase;
  }


  .modal-close-button-cards {

    position:absolute;
    width: 40px;
    height: 40px;
    color: white;
    font-size: 1em;
    font-weight: 800;
    cursor: pointer;
    border-color: white;
    transition: 0.3s;
    background-color: red;
    text-transform: uppercase;
    margin: auto;
    top:15px;
    right:15px;
  }

  .modal-close-button-cards:hover {

    background-color: #ff5252;
    transition: 0.3s;

  }

  .background-section {

    background-color:var(--background-section);
  
  }