.container {
    max-width: 100%;
    padding-bottom:50px;
    color:var(--main-font-color);
  }
  
  .card-row {
    margin:auto;
    width:100%;
    
  }
  
  .card ul {
    margin:auto;
    width:60%;
    display:inline-block;
  }

  .card ul li {

    background-color:var(--main-font-color);
    color:var(--container-color);
    padding:8px;
    margin:1%;
    width:fit-content;
    font-weight:700;
    display:inline-block;
  }


  .card {
    max-width: 800px;
    margin: 30px auto auto auto;
    display:flex;
    border: 3px solid var(--main-font-color);
    flex-direction:row;
    border-radius:3px;
  }
  
  @media (max-width: 799px) { 

    .card {
      display:flex;
      flex-direction:column;
      margin:10px;
      width: calc(100% - 20px);
    }

    .card ul {
      margin:auto;
      width:100%;
      display:inline-block;
      text-align: center;
    }

    .card h3 {
      padding:30px;
      margin:auto;
      width:100% !important;
      font-size: calc(15px + 1vh) !important;
      
    }

  }



  .card img {
    max-width: 100%;
    height: auto;
    overflow:hidden;
    object-fit:cover;
    object-position:center;
  }
  
  .card h3 {
    text-align:left;
    font-weight:800;
    padding:30px;
    margin:auto;
    width:40%;
    font-size: calc(10px + 1vw);
    
  }
  
  .card p {
    width:100%;
    margin: auto;
    border-bottom:1px solid #80808026;
    padding-left:25px;
    padding-right:25px;
    padding-top:10px;
  }
  

  .rental-contact {
    margin:50px auto 50px auto;
    padding: 10px;
    border-radius: 3px;
    text-align:center;
    font-weight:800;
    font-size:calc(18px + 1vw);
    color:var(--main-font-color);
  }

  @import "~react-image-gallery/styles/css/image-gallery.css";

  .gallery {
    

  }
