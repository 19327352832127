.floating-button {
    position: fixed;
    bottom: 70px;
    right: 20px;
    background-color: var(--main-font-color);
    color: var(--container-color);
    font-weight:600;
    padding: 10px 20px;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    z-index: 1; 
    transition:0.3s;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  
  .floating-button:hover {
    background-color: var(--blue-style);
    bacgrkound-image:var(--pattern);
    color:white;
    transition:0.3s;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }


  @media (max-width: 1024px) {

    .floating-button {

      bottom: 10px;
      right: 10px;
     
    }
  }


  