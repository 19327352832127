.class-description {
display:flex;
}


.class-description-pictures {

    width:50%;
    display: flex;  
    flex-wrap: wrap;
    justify-content:center;
}

.class-description-pictures img{
    
    max-width:70%;
    height:400px;
    object-fit:cover;
    overflow:hidden;
    border-radius:3px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin:10px auto 10px auto;
}


.class-description-text {

    width:50%;
    margin-top:50px;
    padding:3%;
}

.class-description-h2 {

    font-size:24px;
    font-weight:800;
    

}


.class-description-p {

    font-weight:500;
    font-size:16px;
    padding:10px;

}

@media screen and (max-width: 1180px) {

    .unique-class-title-lia {
        height:calc(100vh - 60px)!important;
        display:flex;
        margin-bottom:100px;
        background-image:url('./Media/IMG_0749.jpg') !important;
        background-size:cover;
        background-position:top !important;
        background-color:var(--uniqueclasses);
        color:var(--main-font-color);
        position:relative;
     }
    
    .unique-class-title {
        height:calc(100vh - 60px)!important;
        display:flex;
        margin-bottom:100px;
        background-image:url('./Media/IMG_0625.jpg')  !important;
        background-size:cover;
        background-position:top !important;
        background-color:var(--uniqueclasses);
        color:var(--main-font-color);
        position:relative;
    }


 }

 .unique-class-title-lia {
    height:80vh;
    display:flex;
    margin-bottom:100px;
    background-image:url('./Media/tilted_lia.png');
    background-size:cover;
    background-position:right;
    background-color:var(--uniqueclasses);
    color:var(--main-font-color);
 }

.unique-class-title {
    height:80vh;
    display:flex;
    margin-bottom:100px;
    background-image:url('./Media/tilted.png');
    background-size:cover;
    background-position:right;
    background-color:var(--uniqueclasses);
    color:var(--main-font-color);
}

.unique-class-title-left {
    width:50%;
    display:flex;
    justify-content:center;
    flex-direction:column;
 }


 .unique-class-title-right img {

        max-width:50%;
        border-radius:3px;
        height:500px;
        margin:auto;
 }


 @media screen and (max-width: 1180px) { 

    .class-description-pictures img{
        max-width:90%;
        height:100%;
        margin:10px auto 10px auto;
    }

    .class-description {

        display:flex;
        flex-direction:column;

        }


    .class-description-text {

        width:100%;
        margin-top:50px;
    }
    
    .class-description-pictures {

        width:100%;
        display: flex;  
        flex-wrap: wrap;
        justify-content:center;
    }

    .unique-class-title-left {
        width:100% !important;
        display:flex;
        justify-content:center;
        flex-direction:column;
        position:absolute;
        bottom: 0px;
        backdrop-filter: blur(2px);
        background-color: #8080803d;
        color:white;
     }

     .personal-data-lia {

        flex-direction:column;
        margin:auto;
     }

    .unique-class-title-left h1{
        font-size:calc(22px + 1vw) !important;
        font-weight:800;
        text-transform:uppercase;
        letter-spacing:2px;
        margin-left:10px !important;
        padding:20px;
        text-align:center;
     }
     .unique-class-title-left h2{
        font-size:16px;
        font-weight:800;
        text-transform:uppercase;
        letter-spacing:2px;
        margin-left: 10px !important;
        padding:20px;
     }
     .personal-data {
        margin:auto;
       flex-direction:column;
     }  

     .title-short-desc {
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        margin-left: 10px !important;
        padding-left: 25px;
        border-left: 2px solid var(--main-font-color);
      }

      .unique-class-title-lia {

        flex-direction:column;

      }

      .title-short-desc {
        padding-left:5px !important;
        margin:auto !important;
     }

 }

 .unique-class-title-left h1{
    font-size:calc(48px + 1vw);
    font-weight:800;
    text-transform:uppercase;
    letter-spacing:2px;
    margin-left:100px;
    padding:20px;
    
 }


 .unique-class-title-left h2{
    font-size:16px;
    font-weight:800;
    text-transform:uppercase;
    letter-spacing:2px;
    margin-left:100px;
    padding:20px;
 }
 
 .personal-data {
    display:flex;
    justify-content:center;
 }      

 .personal-data a{
    max-width:400px;
    background-color:var(--blue-style);
    color:white;
    text-align:center;
    padding:15px;
    margin:10px;
    text-transform:uppercase;
    font-weight:700;
    transition:0.3s;
 }

 .personal-data a:hover{

    opacity:0.8;
    transition:0.3s;

 }

.list-main {
    font-size:16px;
    font-weight:700;
    padding:10px;
}

 .class-description ul{
    list-style: square;
    padding-left:25px;
 } 
 .class-description ul li{
    padding-left:5px;
    
 }

 .title-short-desc {
    font-size:14px;
    font-weight:600;
    text-transform:uppercase;
    margin-left:100px;
    padding-left:25px;
    border-left:2px solid var(--main-font-color);
    
 }

 .personal-data-lia {

    display:flex;
    justify-content:center;
    
 } 

 .personal-data-lia a{
    max-width:400px;
    background-color:orange;
    color:white;
    text-align:center;
    padding:15px;
    margin:10px;
    text-transform:uppercase;
    font-weight:700;
    transition:0.3s;
 }

 .personal-data-lia a:hover{

    opacity:0.8;
    transition:0.3s;

 }

 .keress-batran {

    width:100%;
    text-align:center;
    margin-bottom:100px;
    background-color:orange;
    color:white;
    font-weight:700;
    font-size:calc(14px + 0.5vw);
    padding:20px;
 }


 .random-div {

    display:flex;
    justify-content:center;
    padding-bottom:100px;
 }

 .rolam-unique {
     padding:20px;
     font-weight:800;
     font-size:24px;
     text-transform:uppercase;
     background-color:var(--main-font-color);
     color:var(--container-color);   
     margin:auto;
     transition:0.3s;
 }

 .rolam-unique:hover {
  opacity:0.8;
  transition:0.3s;
}


.title-short-desc-under {

    width:100%;
    font-weight:700;
    font-size:18px;
    padding:2%;
    background-color:var(--uniqueclasses);
    color:var(--main-font-color) !important;
    margin-bottom:100px;
    color:white;

}