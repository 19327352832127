:root {
  --container-color: #fff9f4;
  --main-font-color: #3e3e3e;
  --pattern: url('./fabric3.png');
  --linearGradient: linear-gradient(135deg, #fdfcfb 0%, #e2d1c3 100%);
  --cardsColor: #fbf5e9;
  --navbar-color: white;
  --transparent: #d8e1dba3;
  --focus: rgb(20, 50, 92);
  --single-post-pattern: var(--pattern);
  --home-image-filter: ;
  --hero-image: url('./Components/Pages/Home/Img/widescreen1.jpg');
  --svg-line:#3e3e3e;
  --action-button:#626161;
  --title-p:#5e5e5e;
  --hero-card:white;
  --hero-image-mobil:url('./Components/Pages/Home/Img/widescreen_mobil.jpg');
  --subscribe-color:#f7f5f5a6;
  --new-nav:#fff9f4a3;
  --background-section:#db709317;
  --about-color:#3e3e3e;
  --blue-style:#36b8c6;
  --lifestyle-bck:white;
  --lifestyle-font:white;
  --uniqueclasses:#F5EFEC;
}

.dark-mode {
  --container-color: #343435;
  --main-font-color: #c6c6c6;
  --linearGradient: linear-gradient(to right, #868f96 0%, #596164 100%);
  --cardsColor: #4a4a4a;
  --navbar-color: #c1c1c1;
  --transparent: #223a48a3;
  --focus: rgb(242, 136, 0);
  --single-post-pattern:;
  --home-image-filter: brightness(70%) contrast(90%) opacity(100%) grayscale(60%);
  --hero-image: url('./Components/Pages/Home/Img/widescreen1.jpg');
  --svg-line:#afbbbf;
  --title-p:white;
  --action-button:#f9e2d2;
  --hero-card:#343435;
  --hero-image-mobil:url('./Components/Pages/Home/Img/widescreen_mobil.jpg');
  --subscribe-color:#262626a6;
  --new-nav:#343435d9;
  --background-section:#ffffff17;
  --about-color:#080707;
  --blue-style:#36b8c6;
  --lifestyle-bck:#343435;
  --lifestyle-font:#393939;
  --uniqueclasses:#3f4041;
}

body {
  background-image: var(--pattern), var(--linearGradient);
  min-height: 100vh;
  font-family: 'Open Sans', sans-serif;
  transition: background-image 0.3s ease, color 0.3s ease;
}

.transition {
  transition: background-image 0.3s ease, color 0.3s ease;
}



@import url('https://fonts.googleapis.com/css2?family=Alkatra:wght@400;500;600;700&family=Caveat:wght@400;500;600;700&family=Dosis:wght@300&family=Indie+Flower&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

html {
  scroll-behavior:smooth;
 
}

//background-image: linear-gradient(to top, #09203f 0%, #537895 100%);

button:focus {
  outline: none !important;
  outline: none;
}
