
.blog-main-container {
    max-width: 100%;
    background-color:var(--container-color);
    margin:auto;
    margin-top:60px;
    
    //background-image:var(--pattern);
}
.blog-main-container-single  {
  color:var(--main-font-color);
  max-width: 1550px;
  background-color:var(--container-color);
  margin:auto;
  margin-top:60px;
  background-image:var(--single-post-pattern);

}



  .blog-options {
    display:flex;
    justify-content:center;
    padding:20px;
    width:100%;
    background-image: var(--pattern);
  }


  @media screen and (max-width: 1024px) {
    .blog-cover-text {
      padding: 2%;
      max-width: 100% !important;
      color: var(--main-font-color)
      
    }

    .blog-box {
      margin: auto;
      height:fit-content !important;
      display:grid !important;
      border-radius:2px !important;
      justify-content:center;
    }
    .blog-options {
      display:grid !important;
    }

    .blog-cover-image {
      width: 100% !important;
      height:450px !important; 
    }

  }


.blog-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1400px;
  min-height: 50vh;
}

.bblog-title-back{
  background-image:url('../Img/blogg.jpg');
  background-size:cover;
  background-position:center;
  height:400px;
  display:flex;
  }
  
  .bblog-title-text {
  min-width:40%;
  font-size:xxx-large;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  color: white;
  border-right-radius: 2px;
  background-color: #3c434578;
  padding: 2%;
  text-align:left;
  margin:auto 0;
  text-transform:uppercase;
  font-weight:700;
  background-image: var(--pattern);
  }

  


  .blog-box {
    margin: 2% auto 2% auto;
    width: 80%;
    min-height:fit-content;
    display:flex;
    margin-bottom: 40px;
    border-radius:2px;
  }
  .blog-cover-text {
    padding: 2%;
    max-width: 60%;
    color: var(--main-font-color)
  }


.blog-cover-image {
  width: 450px;
  min-height:450px;
  object-fit:cover;
  object-position:middle;
  overflow:hidden;
  transition:1s;
  -webkit-filter: brightness(100%);
}

.read-more-blog {
  width:fit-content;
  display: block;
  margin:10px auto 10px auto;
  font-size:14px;
  padding:6px 12px 6px 12px;
  font-weight:600;
  text-transform: uppercase;
  color: white;
  background-color: #1e1e1e;
  transition:0.2s ease-in;
  border-radius: 4px;
  }
  
  .read-more-blog:hover {
    transition: 0.5s ease-in;
          background-color: #626161;
    }



.blog-cover-image:hover {
  -webkit-filter: brightness(70%);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}


.blog-cover-text h1 {
  margin-top: 0;
  font-size: 27px;
  font-weight: 800;
  line-height: 1.4;
  color:var(--main-font-color);
  transition:1s;
  text-transform:uppercase;
  transition:0.2s ease-in;
}

.blog-cover-text h1:hover {
  opacity:0.8;
  transition:0.3s ease-in;
}

.blog-date {
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 400;
  font-style:italic;
  padding:1%;
}

.blog-extract {
  font-size: 16px;
  font-weight:500;
  line-height: 1.4;
  padding:1%;
  margin-bottom:6%;
}

.blog-author {
  margin-right:5px;
  padding-top:15px;
  font-size: 14px;
  font-weight: 400;
  vertical-align: middle;
}


.blog-author-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  margin-left:5px;
  vertical-align: middle;
}



.blog-meta {
display:flex;
width:100%;
justify-content:right;
margin-top:5%;
vertical-align: middle;
}


.searchbar {
  border-radius: 1px;
  border: none;
  color: var(--main-font-color);
  font-size: 1rem;
  font-weight: 700;
  padding: 10px 20px;
  margin: 10px 20px;
  cursor: pointer;
  border: solid;
  border-color: white;
  transition: 0.3s;
 }

 .single-post {


 }
 .single-post-header {
  display:grid;
 }
 .single-post-cover-image {
  width:100%;
  border-radius:3px;
 }
 .single-post-title {
  width:100%;
  padding:2%;
 }

 .single-post-title > p {

  padding-top:2%;
  font-weight:500;
  font-style:italic;
  
  }

.single-post-main-titles {
  border-left: 10px solid white;
  padding:1%;
  font-size: 3em;
  color:var(--main-font-color);
  font-weight:600;
}


 .block-content {
  padding-top:5%;
  padding-bottom:5%;
  max-width:100%;
  padding:3%;
 }

 .block-content p {
padding-top:1%;
padding-bottom:1%;
 }

.block-content img {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius:2px;
}

.block-content blockquote {
  padding-left:2%;
  border-left: 2px solid grey;
}

.block-content a {
  color:var(--main-font-color);
  text-decoration:underline;
}
.trans {
  transition:1s;
}

.home-main-hero-blog {    
  width: 100%;
  padding: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color:var(--main-font-color);
}

.blog-main-title-image {

  width: 100%;
  height: calc(79vh - 60px);
  margin: auto;
  object-fit: cover;
  object-position: center;
  filter: var(--home-image-filter);


}

.home-main-hero-blog h1 {
  font-size: 3em;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-weight:600;
}

 @media screen and (min-width: 1024px) {
  .home-main-hero-blog {
    padding: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .block-content {
    padding-top:5%;
    padding-bottom:5%;
    width:70%;
   }

  .single-post-title {
    width:40%;
   }
  .single-post-cover-image {
    width:75%;
   }

  .single-post-header {
    display:flex;
   }
 .bloc-social-main-side {
  display:flex;
 }
 .social-media-block { 
  width:25% !important;
 }
}

.social-media-block {
  padding:2%;
  border-left:3px solid white;
  border-top:3px solid white;
  width:100%;
  margin:2%;
  margin-bottom:5%;
}


.social-media-block > h2 {

font-weight:600;
font-size:24px;
text-align:left;
padding:2%;

}
.social-media-block > p {

  font-weight:500;
  font-size:18px;
  text-align:left;
  padding-left:2%;
  padding-top:1%;
}

.singlepost-author-bio {

}
.singlepost-author-bio p {
 font-size:14px;
 padding: 1%;
}

.singlepost-author-image {
border-radius:50%;
width:100px;
height:100px;
}

.bio-header {
display:flex;
justify-content:space-between;
justify-items:center;
align-items:center;
padding:2%;
}

.bio-header { padding-right:10%;}

.bio-header p { 
font-size:16px;
font-weight:600;
}


.no-posts-message {
    background-color:var(--main-font-color);
    color:var(--container-color);
    margin:auto;
    height:80px;
    
    max-width:350px;
    padding:20px;
    font-weight:700;
    text-transform:uppercase;   
    display:flex;
    justify-content:center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.no-posts-message p {
 
  margin:auto;
}