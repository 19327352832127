.events-main-container {
    max-width: 100%;
    background-color:var(--container-color);
    margin:auto;
    margin-top:60px;
    padding-bottom:80px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 2px;
    
}

@media screen and (min-width: 800px)  { 

  .events-title-back{
    background-position:center bottom !important;
    }

}

.events-title-back{
    background-image:url('./multisup.JPG');
    background-size:cover;
    background-position:center;
    height:400px;
    display:flex;
    }
    
    .events-title-text {
    min-width:40%;
    font-size:xxx-large;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    color: white;
    border-right-radius: 2px;
    background-color: #3c434578;
    padding: 2%;
    text-align:left;
    margin:auto 0;
    text-transform:uppercase;
    font-weight:700;
    }

    .link {

        color:grey;
        text-decoration:underline;
        font-style:italic;

    }
    .options {
        display:flex;
        justify-content:center;
        padding:20px;
        width:100%;
        height:100px;
        background-image: var(--pattern);
      }



      .eventscontainer {
        margin-top:2%;
        margin-bottom:2%;
        margin-left:auto;
        margin-right:auto;
        max-width: 900px;
        min-height: 200px;
        max-height: 400px;
        background-color: white;
        border-radius: 2px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        padding: 1%;
        display: flex;
      }

      @media screen and (max-width: 800px)  {

        .events-title-text {
          
          font-size:xx-large;
        
        }
        .eventscontainer {
          display:grid;
          max-height: 100% !important;
         }

         .events-title {
          width: 100% !important;
        }
        
        .events-title-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          overflow: hidden;
          border-radius: 2px;
        }

        .events-body {
          width: 100% !important;
          display: grid;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        .events-data {
          width: 100% !important;
          display: flex;
          text-align:center;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }

      .events-title {
        width: 40%;
        position: relative;
      }
      
      .events-title-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
        border-radius: 2px;
      }
      
      .events-title h2 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
        padding: 2%;
        text-align: center;
        background-color: #3c434578;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
        text-transform: uppercase;
        font-weight: 700;
        border-radius:2px;
        color: white;
      }
      
      .events-body {
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      
      .evets-description {
        text-align: center;
        padding:1%;
        word-wrap:anywhere;
      }
      
      .events-link {
        text-align: center;
        margin-bottom: 1em;
      }
      
      .events-data {
        width: 30%;
        display: flex;
        text-align:center;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      
      .datespace {
        display: block;
        margin-top: 1em;
      }
      

      .events-modal-textbox {

        padding:10px;

      }

      .events-modal-title {

        padding: 10px;
        font-weight: 800;
        font-size: 24px;
      

      }

      .events-rectangles-span {
        background-color:grey;
        color:white;
        padding:4px 6px 4px 6px;
        margin-right:4px;
        font-weight:600;
        text-transform:uppercase;
      }

      .events-rectangles-p {

        margin-top: 8px;

      }