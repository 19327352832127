.transparent {
  background-color:var(--new-nav);
  //background-image: var(--pattern);
  transition:0.4s;
  height:60px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  backdrop-filter: blur(10px);
}

.svg-logo-header {
  height:40px;

}


/* .transparent nav a {
  padding-bottom:16px;

} */


.logo-container img {
  height: 100%;
}

.left-logo.isScrolled {
  height: 45px; /* Adjust the height as desired when scrolled */
}


.navbar-sub {
    max-width:1550px;
    width:100%;
    margin: 0 auto;
    transition:0.2s;
    justify-content: space-between;
}
.left-logo {
  color: white;
  padding: 3px;
  margin: 0.5rem;
  transition:  .1s;
  text-decoration:none;
  font-weight: 650;
  font-size:2rem;
  text-transform: uppercase;
  height: 45px;

}
header { 
    position: fixed;
    top: 0;
    display: flex;
    align-items: right;
    height: 60px;
    color:var(--main-font-color);
    justify-content: space-between;
    z-index: 10;
    font-size:14px;
    transition:0.2s;
    padding-right: 2%;
    padding-left: 2%;
    width:100%;
    background-color:var(--new-nav);
    backdrop-filter: blur(10px);
}
.call-to-action {
  padding: 3px;
  padding-bottom:15px;
  margin: 0.5rem;
  transition:  .1s;
  text-decoration:none;
  font-weight: 700;
  text-transform: uppercase;
}

nav a {
  
  padding: 8px;
  padding-bottom:16px;
  transition:  .1s;
  text-decoration:none;
  font-weight: 600;
  text-transform: uppercase;
  
}
nav a.active {
	
    border-bottom: 5px solid;
    box-shadow: 0px -10px 10px -12px rgba(0,0,0,0.45) inset;
}
nav a:hover {
	  
  border-bottom: 5px solid;
  box-shadow: 0px -10px 10px -12px rgba(0,0,0,0.45) inset;
    transition:  .1s;
}

.expand-icon-nav {
  font-size: 1.1rem !important;
  animation: rotation2 0.3s;
  transform: rotate(0deg);
}


.nav-button-smt {
  outline:none !important;
}

.nav-button-smt span{
  padding: 8px;
  padding-bottom:16px;
  transition:  .1s;
  text-decoration:none;
  font-weight: 600;
  text-transform: uppercase;
}

.nav-button-smt span:hover {
  border-bottom: 5px solid;
  box-shadow: 0px -10px 10px -12px rgba(0,0,0,0.45) inset;
    transition:  .1s;
}


.call-to-action.active {
	
  border-bottom: 5px solid #DE8405;
  box-shadow: 0px -10px 10px -12px rgba(0,0,0,0.45) inset;
}
.call-to-action:hover {
  
  border-bottom:5px solid;
  border-color:#DE8405;
  transition:  .1s;
}

header .nav-btn {
	padding: 5px;
	cursor: pointer;
	background-color: transparent;
	border: none;
	outline: none;
	
	display: none;
	opacity: 0;
	font-size: 1.8rem;
    
}
header div,
nav {
	display: flex;
	align-items: right;
  justify-content:right;
}

@media only screen and (max-width: 1180px) {
  .navbar-sub {
    max-width:100%;
    justify-content: space-between;
}

.call-to-action {
  padding-bottom:3px;
}


nav a.active {
	opacity:0.6;
  border-bottom:0px solid;
  box-shadow: none;
}
nav a:hover {
  opacity:0.6;
  border-bottom:0px solid;
  box-shadow: none;
  transition:  .1s;
}

.call-to-action.active {
  opacity:0.6;
border-bottom:0px solid;
box-shadow: none;
}
.call-to-action:hover {
opacity:0.6;
border-bottom:0px solid;
box-shadow: none;
transition:  .1s;
}

	header .nav-btn {

    display: inline;
		visibility: visible;
		opacity: 1;
    border-radius: 2px;
    color:var(--main-font-color);
	}
	header nav {
		position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 0.2rem;
    background:#1F1913;
    color:white;
    transition: 1s;
    transform: translateX(100vw);
    z-index: 999999;
    overflow:scroll;
    padding-left: 10px;
	}
	header nav button:first-child {

    margin-top:60px;

	}

	header .responsive_nav {
		transform: none;
	}
	nav .nav-close-btn {
		position: absolute;
		top: 10px;
    right: 15px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    color:white;
	}
	nav a {
		color: white;
		font-family: 'Poppins', sans-serif;
		font-size: calc(20px + 2vw);
		font-weight: 600;
		line-height: 1.3;
		text-decoration: none;  
    padding-bottom:3px;
    
	}


  @media only screen and (max-height: 600px) {

    header nav {
     overflow:scroll !important;
     height: 100vh !important;
    width: 100vw!important;
      gap:0.5rem !important;
      top: 0%;
    left: 0%;
    
    }

    nav a {

      font-size: calc(14px + 1vw);
      font-weight: 600;
      line-height: 1.2;
      text-decoration: none;  
    }
  
    
  }
}






    .leftspacer {
        padding: 5px;
        background-color: transparent;
        border: none;
        outline: none;
        visibility: hidden;
        opacity: 0;
        font-size: 1.8rem;
        box-shadow: rgb(0 0 0 / 10%) 0px 1px 2px 0px;
    }


    .switch {
      width: 60px;
      height: 30px;
      border-radius: 15px;
      background-color: #ccc;
      position: relative;
      cursor: pointer;
      transition:  0.2s ease;
      
    }
    
    @media only screen and (max-width: 1180px) {
      .switch {
        position:absolute;
        top: 50px;
        left: 30px;
        margin-right:0;
        margin-left:0;
      }
    }
    

    
    .slider {
      width: 26px;
      height: 26px;
      border-radius: 50%;
      background-color: #fff;
      position: absolute;
      top: 2px;
      left: 2px;
      transition: transform 0.2s ease;
      background-image: url('./Components/Switch/sun.png'); /* Add the sun icon image */
      background-size: 100% 100%;
      background-position: center;
    }
    
    .switch.on {
      background-color: #123057;
      transition: transform 0.2s ease;
      outline:none !important;
    }
  
    .switch.off {
      background-color: #85b2ec;
      transition: transform 0.2s ease;
      outline:none !important;
    }
    
    .switch.on .slider {
      background-image: url('./Components/Switch/moon.png'); /* Add the half-moon icon image */
      background-size: 100% 100%;
      background-position: center;
      transform: translateX(30px);
    }
    
    .switch.off .slider {
      transform: translateX(0);
    }
    
    .dropdown-wrapper {
      position: relative;
    }
    

    

    .dropdown-content {
      font-size:12px;
      display: none;
      background-color: var(--new-nav);
      color: var(--main-font-color);
      z-index: 1;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 4px;
      transition:0.3s;
    }
    
    .dropdown-opened {
      transition:0.3s height;
      display: block;
      top: 100%;
      left: 0;
      min-width:180px;
      position: absolute;
    }
    


    .dropdown-content a {
      display: block; /* Ensure each item appears on a new line */
      padding: 8px; /* Add padding for better spacing */
      text-decoration: none; /* Remove default link underline */
       /* Set text color */
      transition:0.1s;
    }
    
    .dropdown-content a:hover {
      color: var(--container-color); /* Add background color on hover */
      background-color:var(--main-font-color);
      transition:0.1s;
      border:none;
    }

    .dropdown-content a.active {
      color: var(--container-color); /* Add background color on hover */
      background-color:var(--main-font-color);
      border:none !important;
    }


    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(180deg);
      }
    }
    @keyframes rotation2 {
      from {
        transform: rotate(180deg);
      }
      to {
        transform: rotate(0deg);
      }
    }


    .expand-icon {
      font-size: 2rem !important;
      animation: rotation2 0.3s;
      transform: rotate(0deg);
    }



    .twisted {
      animation: rotation 0.3s;
      transform: rotate(180deg);
    }


    @media only screen and (max-width: 1180px) {

 

      .mobile-button-smt {
        padding-right: 8px;
        color: white;
        font-family: 'Poppins', sans-serif;
        font-size: calc(20px + 2vw);
        font-weight: 600;
        line-height: 1.3;
        text-decoration: none;  
        padding-bottom:3px;
        text-transform:uppercase;
        text-align:right;
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:center;
      }
    
      
      .mobile-button-smt.active {
      opacity:0.6;
      border-bottom:0px solid;
      box-shadow: none;
    }
    .mobile-button-smt:hover {
      opacity:0.6;
      border-bottom:0px solid;
      box-shadow: none;
      transition:  .1s;
    }


      .dropdown-content-navlink {
        visibility:hidden;
        overflow:hidden;
        height:0px;
        width:0px;
        transition:0.3s height;
        text-align:right;
        font-size:calc(14px + 0.5vw);
        display:none;
      }
  
      .dropdown-button-opened {
        visibility:visible;
        transition:0.3s height;
        height:50px;
        width:100%;
    
        display:flex;
        flex-direction:column;
      }

      .dropdown-content {
        width:100%;
        background-color:transparent !important;
        box-shadow:none;
       }
      .dropdown-content a {
        
        text-align:right;
        padding: 10px;
        padding-bottom:16px;
        transition:  .1s;
        text-decoration:none;
        font-weight: 600;
        text-transform: uppercase;
        font-size: calc(20px + 2vw);
        line-height: 1.3;
        padding-top:0px !important;
        padding-bottom:0px !important;
      }
      
      .dropdown-content a:hover {
        opacity:0.6;
        border-bottom:0px solid;
        box-shadow: none;
        transition:  .1s;
        background-color: transparent; /* Add background color on hover */
        color:white !important;
      }
      .dropdown-content a.active {
        opacity:0.6;
        border-bottom:0px solid;
        box-shadow: none;
      }



    }