.popup2-window {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 80vw;
    max-height: 90vh;
    outline: none;
    box-sizing: border-box;
}

.pop2-left, .pop2-right {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.popup2-image {
    max-height: 70vh; /* Adjusted for close button and action button space */
    max-width: 100%;
    object-fit: contain;
}

.pop2-right button, .pop2-left button {
    padding: 5px;
}

/* Ensure action button has a uniform height with the close button row */
.pop2-right button, .pop2-left .popup2-colse-button-2 {
    height: 48px; /* Example height, adjust based on your design */
}

.pop2-right  {

    display:flex;
    justify-content:center;
    
}

.pop2-right button {
    background-color:var(--main-font-color);
    color:var(--container-color);
    width:100%;
    font-weight:700;
    transition:0.3s;
}

.pop2-right button:hover {
    transition:0.3s;
    opacity:0.8;
}

.pop2-left button {
    color:white;
    font-weight:700;
    background-color:#ed3d3d;
    transition:0.3s;
    padding:10px;
}

.pop2-left button:hover {

    background-color:red;
    transition:0.3s;
    
}

@media screen and (max-width: 768px) {
 

    .popup2-window {
        width: 100vw !important;
        max-width: 100vw !important;
        max-height: 90vh !important;
    }


  }