.footer {
  background-color: var(--container-color);
  margin-top: 10%;
  color: var(--main-font-color);
}

.footer-h2 {
  font-weight: 100;
font-size: 1.8em;
margin-top: 1%;
margin-bottom: 5%;
}

.footer-main {
  display: flex;
  flex-direction: column;
  padding: 60px 0;
  align-items: center;
  text-align: center;
  transition: flex 0.5s ease; /* Add transition */
}

.column {
  display: flex;
  flex-direction: column;
  transition: flex 0.5s ease; /* Add transition */
  margin-bottom:50px;
}

.logo-column {
  order: 1;
  padding-inline: 5%;
}

.logo-column p {
  margin-top: 3%;
  margin-bottom: 7%;
}

.contact-column {
  order: 2;
}

.contact-column ul li {
  margin-bottom:15px;
}

.top-button {

  

}

.follow-column {
  order: 3;
}

.logo {
  max-width: 100px;
  align-self: center;
  margin: 1%;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  width: 200px; /* adjust this value based on your design */
    margin: auto;
}

.social-icons a {
  margin-right: 10px;
}

.footer-copyright {
  background-color: var(--cardsColor);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px; /* Increase the right padding */
  text-align: center;
}

.footer a {
 
}

.social-icons a:hover {
  opacity: 0.7;
}

/* Media queries for larger screens REVERSE */
@media (min-width: 1024px) {
  .footer-main {
    flex-direction: row;
    align-items: unset;
    text-align: unset;
  }
  .logo {
    align-self: start;
  }
  .logo-column {
    flex: 0.4;
  }

  .contact-column,
  .follow-column {
    flex: 0.3;

  }

  .logo-column,
  .contact-column,
  .follow-column {
    order: unset;
    padding: 50px;
    margin:0%;
  }

  .sitemap {

    display:flex;
    flex-direction:column;
    margin: 0px !important;
  }
  
  .social-icons {
    margin: 0;
  }

}


.sitemap {

  display:flex;
  flex-direction:column;
 
    margin: auto;
 
}

.sitemap h4 {

  font-weight:400;
  margin-bottom:2px;
  padding-bottom:4px;
  border-bottom: solid 1px grey;
  width:200px;

}

.sitemap a{

font-size:12px;
transition:0.3s;
}

.sitemap a:hover{

  padding-left:2px;
  color:grey;
  transition:0.3s;
  }