.about-text {
margin:20px auto auto auto;
text-align:left;
font-weight:600;
padding:1%;
}
.highlighted {
  font-weight:800;
  padding:1%;
  margin-top:20px;
}
.aboutme-inside-container {
 color: var(--about-color) !important;
  max-width:800px;
  margin:auto auto 200px auto;
  padding: 12px;
}

.aboutme-inside-container img {

  border-radius:3px;
   
 }

.about-img {
    max-height:600px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; 
    border-radius:3px;
    opacity:0.9;
    transition:0.2s;
}

.about-img-transparent {
  max-height:600px;
  transition:0.2s;
}

.text-container {
margin:auto;
width:50%;
padding:2%;
}


.about-title {
   
    padding: 150px 1% 10% 1%;
    
  }
  
  .title-first {
    font-size: 5em;
    font-weight: 600;
    
    text-transform: uppercase;
    position: relative;
    margin-bottom: 10px;
  }
  
  .title-second {
    font-size: 5em;
    font-weight: 600;
    
    text-transform: uppercase;
    position: relative;
  }
  
  .title-first, .title-second {
    width:fit-content;
  }

  .title-first::after,
  .title-second::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -3px; 
    height: 2px;
    background-color: var(--main-font-color);
    width: 0;
    transition: width 1s ease-in-out;
    animation: underline 1s ease-in-out forwards;
    animation-delay: 2s;
  }
  
  @keyframes underline {
    from {
      width: 0;
      opacity: 0;
    }
    to {
      width: 100%;
      opacity: 1;
    }
  }
  
 
  .title-first::after,
  .title-second::after {
    width: 0;
  }
  

  .title-first::after {
    animation-delay: 2s;
  }
  
  .title-first:hover::after,
  .title-second:hover::after {
    width: 100%;
  }
  
  .title-first:hover::after,
  .title-second:hover::after {
    animation: none;
    opacity: 0;
    width: 0;
    transition: width 0s ease-in-out;
    animation-delay: 0s;
  }
  
  @media screen and (max-width: 800px) {
    .about-first,
  .about-second,
  .about-third,
  .about-fourth,
  .about-fifth,
  .about-sixth,
  .about-seventh,
  .about-eigth,
  .about-ninth {
    display:grid;
  }
  .text-container {
    width:100%;
    }
  }