@media screen and (max-width: 1024px) {
    .main-contactus-section {
      display:grid !important;
     }

     .main-contactus-section > div { 
      width:100% !important;
     }

     .main-contactus-section > div > section { 
      height:auto !important;
      
     }

  }

  .home-main-container-contact {
    max-width: 1550px;
    margin: auto;
    margin-top: 60px;
    }
      
    
    .main-contactus-section {
      display:flex;
      width:100%;
    }
     .main-contactus-section > div {
        margin-top:5%;
        width:50%;
        
      }
    
      .main-contactus-section > div > section {
        margin-top:20%;
        height:60%;
        display:grid;
        justify-content:center;
        justify-items:left;
        border-left:solid 5px lightgrey;
        border-radius:2px;
        margin-left:5%;
      }
    
      .main-contactus-section > div > section > p {
        padding:15px;
        text-align:left;
        font-size:1.3em;
        font-weight:550;
        color:var(--main-font-color);
      }


      .contactus-section {
        display:flex;
        border-color: white;
        border-style: solid;
        border-width:2px;
        padding:2%;
        margin-bottom: 80px;
        margin-top: 20px;
        width:100%;
        box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
      }

      .map {
        
        width:33.3%;
        height:400px;
        display:flex;
        justify-content:center;
        background-color:var(--container-color);
      }

      .phone {
        
        width:33.3%;
        height:400px;
        background-color:var(--container-color);
        display:flex;
        justify-content:center;
      }

      .social {
        
        width:33.3%;
        height:400px;
        background-color:var(--container-color);
        display:flex;
        justify-content:center;
        
        
      }

      .line-art {
        height:inherit;
        stroke: var(--main-font-color);
        fill: var(--main-font-color) !important;
        color:var(--main-font-color);
        padding:2%;
      }

      .cls-1 {
        stroke: var(--main-font-color);
        fill: var(--main-font-color) !important;
        color:var(--main-font-color);
      }
      .contactus-text {
        
       
        z-index: 1;
        color:var(--main-font-color);
        width:70%;
        font-weight:500;
        font-size:1.5rem;
        padding:8%;
        margin:auto;
        text-align:center;
        
      }

      .contactus-social-icons {
        display:grid;
        margin:auto;
      }

      .contactus-social-icons a {
        margin:10px;
        background-color:beige;
        border-radius:50%;
      }

      .contactus-social-icons a:hover {
        opacity: 0.7;
      }

      .circle {
        border-radius:4px;
        height:180px;
        width:180px;
        margin:auto;
        text-align:center;
        transition:0.8s ease;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: var(--main-font-color);
        font-weight: 600;
        font-size:calc(10px + 1vw);
      }

      .circle:hover {
        opacity:0.7;
        transition:0.8s ease;
      }

      .inner-circle {
        

      }

      @media only screen and (max-width: 1024px) {
        .main-contactus-section {
          display: grid !important;
        }
      
        .main-contactus-section > div {
          width: 100% !important;
        }
        .circle { 
          margin:10px !important;
        }
        .main-contactus-section > div > section {
          height: auto !important;
        }
      
        .home-main-container-contact {
          max-width: 100%;
          margin-top: 60px;
        }
      
        .main-contactus-section {
          flex-direction: column;
        }
      
        .main-contactus-section > div {
          width: 100%;
          margin-top: 0;
        }
      
        .main-contactus-section > div > section {
          margin-top: 0;
          height: auto;
          border-left: none;
          margin-left: 0;
          border-radius: 0;
          text-align: center;
        }
      
        .main-contactus-section > div > section > p {
          text-align: center;
          font-size: 1.3em;
        }
      
        .contactus-section {
          display: flex;
          flex-direction: column;
          margin-bottom: 40px;
          margin-top: 20px;
        }
      
        .map,
        .phone,
        .social {
          width: 100%;
          height: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          
        }
      
        .line-art {
          
          max-width: 300px;
          height: auto;
          padding:1%;
        
        }
      
        .contactus-text {
          transform: none;
          width: 80%;
          font-size: 1.5rem;
          
          text-align: center;
          padding: 8%;
          margin:20px;
        }
      
        .contactus-social-icons {
          grid-template-columns: repeat(3, 1fr);
          margin: auto;
          width: 85%;
          padding-bottom:3%;
        }
      
        .contactus-social-icons a {
          margin: 10px;
        }
      
        .circle {
          height: 180px;
          width: 180px;
          margin: auto;
        }
      }
      