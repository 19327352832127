.book-main {
    min-width:100vw;
    min-height:100vh;
    position:absolute;
    top:0;
    left:0;
    z-index:0;
    
}

.book-modal {
    text-align:center;
    margin:auto;
    padding-top:1%;
    padding-bottom:1%;
    background-image:var(--pattern);
}

.modal-close {
position:absolute;
top:4%;
right:4%;
}


.book-title-back{
background-image:url('./Img/Class.jpg');
background-size:cover;
background-position:center;
height:400px;
display:flex;
}

.book-title-text {
min-width:40%;
font-size:xxx-large;
box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
color: white;
border-right-radius: 2px;
background-color: #3c434578;
padding: 2%;
text-align:left;
margin:auto 0;
text-transform:uppercase;
font-weight:700;
}

.openbutton {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 1px;
    border: none;
    color: white;
    font-size: 1rem;
    font-weight:700;
    padding: 10px 20px;
    margin: 10px 5px;
    cursor: pointer;
    border:solid;
    border-color:white;
    transition:0.3s;
    background-color:white;
    color: #71717a;
}

  
  .openbutton:hover {
    
    border-radius: 1px;
    transition:0.5s;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }


  .book-main-container {
    min-height: 650px;
    background-color:var(--container-color);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius:2px;
    max-width:100%;
    margin:auto;
    margin-top:60px;
  }

  .modal-box-style {
    position:relative;
    max-height: 90%;
    max-width: 800px;
    background-color: var(--container-color);
    border: 2px solid #ffffff3d;
    boxShadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding:6%;
    color: var(--main-font-color);
    overflow: scroll;
    z-index:9999;
    margin:auto;
  }

  .booking-titles {

    max-width:1440px;
    margin:auto;
  }

  .booking-titles h2 {
    font-weight:800;
    font-size:2rem;
    color:var(--container-color);
    background-color:var(--main-font-color);
    padding:16px;
    margin-top:20px;
    margin-left:20px;
    margin-right:20px;
    border: 3px solid var(--main-font-color);
    text-transform:uppercase;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    
  }


  .cookie-consent {

    display:none !important;

  }