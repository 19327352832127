.lifestyle-container {

    margin-top:60px;
    width:100%;
    background-color:var(--lifestyle-bck);
    margin-bottom:120px;
}

/* .lifestlye-title-section {

    background-color:var(--blue-style);
    color:white;
    display:flex;
    flex-direction:row;
    
}
 */
.lifestlye-title-section {
    background-image:url('./Media/kettenscaled.png');
    background-position:center;
    height:90vh;
    background-size: cover;
    color:white;
    display:flex;
    flex-direction:row;
}


.services-timetable-group {

    display:flex;

}

.lifestyle-services-section {

    width:100%;
    margin:0px auto 100px auto;
    
}





.title-text {
    width:40%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    padding-right:24px;
    background-image:var(--pattern);
    font-weight:650;
    font-size:24px;
    position:absolute;
    height:90vh;
}

.title-text section {
    display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 18px;
  margin: auto;
  border: 3px solid white;
  position: relative;
  left: 20%;
  -webkit-backdrop-filter: blur(5px) contrast(70%);
  backdrop-filter: blur(5px) contrast(70%);
  max-width: 100%;
  min-width:75%;
}
.title-text h1{
    font-weight: 800;
  font-size: calc(38px + 0.5vw) !important;
  text-transform: uppercase;
  text-align: right;
  text-shadow: 0.5px 0.5px 0.5px #000;
  display: flex;
  flex-direction: column;
    }

.cim-egy {
text-align:right;
}
.cim-ketto {
    text-align:right;
}
.cim-harom {
    text-align:right;
}
.title-text h2{
    font-weight:600;
    font-size: calc(12px + 0.5vw) !important;
    text-transform:uppercase;
    text-align:right;
    text-shadow: 0.5px 0.5px 0.5px #000;
    }
    
.title-pic {
    width:60%;
    height:80vh;
    object-fit:cover;
    object-position:center;
    padding: 10px;
  background-image: var(--pattern);
  border-radius:12px;
  display:none;
}

.title-logos {
    display:flex;
    flex-direction:row;
    justify-content:center;
    max-width:220px;
    margin:auto;
    
    padding:12px;
    border-radius:1px;
    
}

.title-logos-each {

    height:40px;
    margin:auto 10px auto 10px;
    object-fit:cover;
    color:grey;

}


.detailed-plan-table {

    display:flex;
    flex-direction:column;
    justify-contnet:center;
    margin:auto;
    width:100%;
    background-color:var(--blue-style);
    background-image: var(--pattern);
    padding-top:50px;
    padding-bottom:50px;
}

.plan-row {
    margin-left:auto;
    margin-right:auto;
    width:600px;
    
    background-color:white;
    margin-top:5px;
    margin-bottom:5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius:3px;
    padding:10px;
    padding-left:0;
    display:flex;

}

.plan-first {
    width:25%;
    margin-top:auto;
    margin-bottom:auto;
    padding-left:5px;
}

.plan-row span {
    
    margin: auto;
    padding:3px;
    font-size:14px;
    padding-left:5px;
    font-weight:400;
    color:#707070;
}

.plan-second {
    width:30%;
    margin-top:auto;
    margin-bottom:auto;
    padding-left:10px;
    padding-right:10px;
}

.plan-third {
    width:50%;
    margin-top:auto;
    margin-bottom:auto;
   
   
}

.change-right {
color:white;
text-align:center;
font-style: italic;
}


.plabel {

    text-transform:uppercase;
    font-weight:700;
    background-image:var(--pattern);
}

.lifestyle-timetable-section {

    
    widht:100%;

}

.lifestyle-apply-section {
    color:var(--main-font-color);
    max-width:1440px;
margin:auto;
    padding-top:120px;
    padding-bottom:120px;
    display:flex;
    flex-direction:row;
}

.lifestyle-price {
display:flex;
flex-direction:column;
width:50%;
}

.lifestyle-price div {
   display:flex;
    }

    .lifestyle-price div p {
        background-color:var(--main-font-color);
        color:var(--lifestyle-font);
        font-weight:700;
        font-size:18px;
        min-height:50px;
        margin:5px;
       width:320px;
       padding:10px;
    }
.newlinep {
    
}
    .lifestyle-price div  span{
        background-color:var(--blue-style);
        color:white;
        font-weight:700;
        font-size:18px;
        padding:10px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        margin-top:auto;
        margin-bottom:auto;
        min-height:50px;
        background-image:var(--pattern);
        text-align:center;
    }

.price-price {

    margin:40px auto auto auto;
    display:flex;
    justify-content:center;

}
.price-price p {
    background-color:var(--main-font-color);
    color:var(--lifestyle-font);
    font-weight:700;
    font-size:28px;
    min-height:50px;
    margin:5px;
   width:220px;
   padding:10px;
   text-align:center;
}
.price-price span{
    background-color:var(--blue-style);
    color:white;
    font-weight:700;
    font-size:28px;
    padding:10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    margin-top:auto;
    margin-bottom:auto;
    min-height:50px;
    background-image:var(--pattern);
    text-align:center;
}

.part-payment {

    margin-top:20px;

}

.part-payment p{

    background-color:var(--main-font-color) !important;
    color:var(--container-color);
}
.lifestyle-price h2 {

    
}

.apply-process {
    
    width:50%;
    
}
.apply-process h3 {

    background-color:var(--main-font-color);
    color:var(--lifestyle-font);
        font-weight:700;
        font-size:18px;
        min-height:50px;
        margin:5px;
       width:320px;
       padding:10px;
}

.apply-process ul {
    padding:10px;
    padding-left:20px;
    list-style-type: square;
    
}

.apply-process ul li{
    padding:5px;
    color:var(--main-font-color);
    
}

.lifestyle-explanation-section {

max-width:1440px;
margin:60px auto 20px auto;
padding:20px;

border-radius:5px;

}

.lifestyle-explanation-section h2{
    background-color:var(--main-font-color);
    color:var(--lifestyle-font);
    font-weight:700;
    font-size:18px;
    min-height:50px;
    margin:5px;
    max-width:320px;
   padding:10px;
    

}

.lifestyle-explanation-section ul {
    padding:10px;
    padding-left:20px;
    list-style-type: square;
    
}

.lifestyle-explanation-section ul li{
    padding:5px;
    color:var(--main-font-color);
    
}

.motivation-text {
    width:100%;
    background-color:var(--blue-style);
    background-image:var(--pattern);
    min-height:120px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    margin-top:50px;
    padding:20px;
}

.motivation-text p{
    max-width:1440px;
    margin:auto;
    font-size:calc(12px + 0.5vw);
    font-weight:750;
    color:white;
    text-transform:uppercase;
    padding-bottom:20px;
    text-align:center;
}

.lifestyle-detailed-plan-seection {

    display:flex;
    flex-direction:column;
    justify-contnet:center;
    margin:auto auto 50px auto;
    width:100%;
    background-color:var(--blue-style);
    background-image: var(--pattern);
    padding-top:50px;
    padding-bottom:50px;
    transition:1s ease-out;

}
.lifestyle-detailed-plan-seection h2{


    max-width:1440px;
    font-size:calc(32px + 2vw);
    font-weight:800;
    color:white;
    text-transform:uppercase;
    margin:auto;
}

.google-form-iframe {

    width:100%;
    height:2000px;
    margin:auto;
    

}

.lifestyle-picture-slide {

    height: 300px;
    display: flex;
    flex-direction: row;
    oveflow-x: hidden;
    background-color:black;
    overflow-x: clip;
}

.lifestyle-picture-slide img{
    margin:1px;
    max-height:300px;
    width:auto;
    object-fit:cover;
    
  
}

.google-form-section {

   width:100%;
   height:200px;
    display:flex;
    justify-content:center;
}
.google-form-link {

    margin:auto;
    text-align:center;
    width:300px;
    background-color:var(--blue-style);
    color:white;
    padding:12px;
    font-size:24px;
    font-weight:700;
    text-transform:uppercase;
    border-radius:2px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition:0.3s;
    
}
.google-form-link:hover {
    opacity:0.7;
    transition:0.3s;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
   
}

.closed {

    height:500px;
    overflow:hidden;
}

.open {

    height:auto;

}

.lifestyle-timetable-section {

    max-width: 600px;
    margin:auto;    
    color:white;
}

.lifestyle-timetable-section h4{
  
    background-color:var(--main-font-color);
    color:var(--lifestyle-font);
    font-weight:700;
    font-size:18px;
    min-height:50px;
    margin:5px;
   max-width:320px;
   padding:10px;
}
.lifestyle-timetable-section 
ul{
  
padding:10px;
padding-left:20px;
list-style-type: square;

}
.lifestyle-timetable-section ul li{
    padding:5px;
    color:white;
    font-weight:700;
    font-size:20px;
    text-transform:uppercase;
}


.lifestyle-timetable-section h3{
  
    background-color:white;
    color:#3e3e3e;
    font-weight:800;
    font-size:24px;
    min-height:50px;
    margin:5px;
    text-transform:uppercase;
   width:320px;
   padding:10px;
}

.lifestyle-timetable-section p{
  
    
    
    font-weight:600;
    font-size:22px;
    min-height:50px;
    margin:5px;
   padding:10px;
}

.services-title {
    width:100%;
    background-color:var(--blue-style);
    background-image:var(--pattern);
    font-weight:800;
    text-transform:uppercase;
    color:white;
    padding:50px;
    text-align:center;
    padding-left:50px;
    font-size:calc(24px + 1vw);
    margin-bottom:100px;
}

.services-boxes {
    display: flex!important;
    flex-wrap: wrap; /* Enable wrapping */
    justify-content: center; /* Adjust spacing between boxes */
    margin:20px;
  }
  
  .services-box {
    background-color: white;
    background-image: var(--pattern);
    color: #3b3b3b;
    width: 250px;
    height: 250px;
    margin:20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 25px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    border-radius: 3px;
  }

.mobile-wide-sectioner {
    display:flex;
    flex-direction:row;
}

.mobile-wide-sectioner-2 {
    display:flex;
    flex-direction:row;

}
.mobile-wide-sectioner section{
    width: 50%;
  
    margin: auto;
    padding: 20px;
    overflow: hidden;
}
.mobile-wide-sectioner-2 section{
    width: 50%;
    margin:10px auto 10px auto;
    padding: 20px;
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
    height: 600px;
    margin:auto;
    
}

.show-more-button {
    margin:auto;
    background-color:white;
    padding:12px;
    border-radius:3px;
    
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    font-weight:600;
    position:relative;
    bottom:20px;
    transition:0.1s;
    left: 50%;
transform: translateX(-50%);
}
.show-more-button:hover {
    transition:0.3s;
    opacity:0.95;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

  @media (max-width: 1024px) {
    
    .cim-egy {
        text-align:center;
        }
        .cim-ketto {
            text-align:center;
        }


    .mobile-wide-sectioner {
        display:flex;
        flex-direction:column;
    }
    .mobile-wide-sectioner-2 {
        display:flex;
        flex-direction:column;
    }
    .image-gallery-content .image-gallery-slide .image-gallery-image {
        height: 400px;
        margin:auto;
    }
    
    .mobile-wide-sectioner section{
        width:100%;
        height:auto;
        
    }

    .mobile-wide-sectioner-2 section{
        width:100%;
        margin: auto;
        padding: 20px;
    }
    .lifestlye-title-section {
        background-image:url('./Media/kettentevekeny.jpg') !important;
        background-position:center!important;
        height:90vh;
        background-size: cover;
    }

    .title-pic {

        display:none;

    }

    .services-box {
      width: calc(50% - 20px); /* Two boxes per row */
      margin: 20px; /* Add space between rows */
    }

    .lifestyle-apply-section {
   

        padding-top:120px;
        padding-bottom:120px;
        display:flex;
        flex-direction:column;
    }
    .lifestyle-price div span{
        width:35%;
        text-align:center;
        margin:auto;
    }
    .lifestyle-price div p {

        max-width:60%;

    }
    .price-price {
        flex-direction:column;
    }
    .price-price p {

        width:90%;
        margin:5px auto auto auto;
        
    }
    .price-price span {

        width:90%;
        margin:5px auto auto auto;
        
    }
    .apply-process {

        width:90%;
        margin:50px auto auto auto;

    }

    .lifestyle-detailed-plan-seection {
        width:100%;
    }
    .plan-row {

        width:100%;
        
    }

    .lifestlye-title-section {

        flex-direction:column;

    }

    .title-text {
        width:100%;
        padding-right:0;
       position: sticky;
        top: 70vh;
        height: 30vh;
        
    }

    .title-text h1{
        text-align:center;
    }
    .title-text h2{
        text-align:center;
    }
    .title-pic {
        width:100%;
        height:60vh;
        padding:0;
        border-radius:0;
    }
    
    .title-text section {
        position:absolute;
        backdrop-filter: blur(2px);
        left: 50%;
        transform: translateX(-50%);
        width:90%;
        bottom:10%;
    }
.lifestyle-price {

width:100%;

}


.motivation-text p{

    font-size:calc(16px + 0.5vw);
}

  }
  
  @media (max-width: 480px) {
    /* Adjust box width for even smaller screens */
    .services-box {
      width: calc(100% - 20px); /* One box per row */
      margin: 20px; /* Add space between rows */
    }
  }

.services-box h4{
  
    font-weight:800;
    text-transform:uppercase;
    margin:auto;
    font-size:20px;
   
 }

 .services-box ul{
    list-style-type: square;
    padding-left:15px;
    text-align: left;
}

.services-box ul li{
    
    
}

.felsorolas-szeru {

    text-align: left;
    padding-left:15px;
}

.image-gallery {
    padding-top:50px;
    padding-bottom:100px;
}

.image-gallery-section {
    background-color:#3e3e3e05;
}