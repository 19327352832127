
.home-main-layer {
    margin-top:5%;
    margin-bottom:5%;
    margin:auto;
    max-width:100%;
    transition:0.3s;
    
}

@media screen and (max-width: 1024px) {


  .new-main-title {
    display:flex;
    width:100% !important;
    background-color:var(--cardsColor);
    padding: 80px 0px 0px 0px;
    flex-direction:column;
   }
   
   .new-main-hero {
     position: relative;
     overflow: hidden;
     width:100% !important;
     height:70vh !important;
     transition:0.2s;
     border-radius:3px;
     background-image: var(--hero-image) !important;
     background-size: cover; /* Adjusts the size of the background image */
     background-position: center top;
     transition: 0.3s;
     padding:10px;
   }
   
   .new-main-hero-2 {
     width:100% !important;
     padding:10px;
     height:100%;
     display:flex;
     flex-direction:column;
     margin:auto;
     
   }
   
   .new-main-hero-2-tile {
      margin: auto;
      background-color:var(--hero-card);
      width:fit-content;
      border-radius:3px;
      padding:10px !important;
      position:relative;
      left: 0px !important;
      top: -160px;
      text-align:center;
   }


  .home-main-hero {
    width: 100% !important;
    min-height: 460px;
    max-height:100% !important;
    padding: 1% !important;
    color:white !important;
  }

  .home-main-title {
    background-image: var(--hero-image-mobil) !important;
    background-size: cover; 
    background-position: center 33% !important;
    height:calc(100vh - 60px) !important;
  }
  
  .home-main-hero h1 {
    font-size: calc(5em + 3vw);
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    font-weight:700;
    
  }

.new-main-hero-2 p {

  margin: 10px !important;

}
.new-main-hero-2 h1 {

  margin: auto auto auto auto !important;
  text-align:center;
}

.new-main-hero-2 button {
    margin:40px auto 40px auto !important;
}

}

.new-main-title {
 display:flex;
 width:100%;
 background-color:var(--cardsColor);
 padding: 80px 0px 0px 0px;
 
}

.new-main-hero {
  position: relative;
  overflow: hidden;
  width:50%;
  height:80vh;
  transition:0.2s;
  border-radius:3px;
  background-image: var(--hero-image);
  background-size: cover; /* Adjusts the size of the background image */
  background-position: center top;
  transition: 0.3s;
  padding:10px;
}

.new-main-hero-2 {
  width:50%;
  padding:10px;
  height:100%;
  display:flex;
  flex-direction:column;
  margin:auto;
}

.new-main-hero-2-tile {

  background-color:var(--hero-card);
  width:fit-content;
  border-radius:5px;
padding:30px;
position:relative;
left: -50px;

}

.new-main-hero-2 h1 {
    color: var(--main-font-color);
    font-size: calc(3rem + 3vw);
    margin: auto 10px auto 10px;
    text-transform: uppercase;
    font-weight:700;
}

.new-main-hero-2 p {
  color: var(--main-font-color);
  font-size: 16px;
  margin: 25px auto 25px 20px;
  font-weight:700;
  max-width:700px;
}

.new-main-hero-2 button {
  font-size: 1.2em;
  font-weight:800;
  padding: 12px 20px;
  margin: 25px auto 25px 20px;
  cursor: pointer;
  transition:0.3s;
  text-transform:uppercase;
  border-radius:3px;
  border: 2px solid var(--main-font-color);
  color:var(--container-color);
  background-color:var(--main-font-color);
  transition:0.2s ease-in;
}

.new-main-hero-2 button:hover {
  color:var(--main-font-color);
  background-color:var(--container-color);
  transition:0.2s ease-in;
}

.headline {
max-width: 1550px;
margin:auto;
padding-left:3%;
padding-right:3%;
}

.headline p {
  
  text-align:center;
  font-weight:800;
  font-size:calc(16px + 0.2vh);
  color:var(--main-font-color);
  line-height:1.6;
  }

.home-main-title {
    position: relative;
    overflow: hidden;
    height:calc(90vh - 60px);
    transition:0.2s;
    border-radius:2px;
    background-image: var(--hero-image);
    background-size: cover; /* Adjusts the size of the background image */
    background-position: center bottom;
    transition: 0.3s;
    margin-top:60px;
  }
  
  .home-main-hero {
    position: absolute;
    bottom: -50px;
    left: 0;
    width: 80%;
    max-height: 580px;
    min-height: 460px;
    padding: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-top-right-radius:2px;
    color:white;
  }
  
  .home-main-hero h1 {
    font-size: calc(5em + 3vw);
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    font-weight:700;
    	
text-shadow: 3px 2px 0px rgba(0,0,0,0.4);
  }
  
  .home-main-hero h2 {
    font-size: 4em;
    font-weight: 700;
    padding: 0;
    text-transform:uppercase;
  }
  
  .home-main-hero p {
    font-size: 1em;
    font-style:italic;
    margin: 5px 0;
    padding: 0;
    font-weight:700;
  }
  
  .home-main-hero button {
    
    
    color: white;
    font-size: 1.2em;
    font-weight:800;
    padding: 12px 20px;
    margin: 10px 0 10px 5px;
    cursor: pointer;
    border-color:white;
    transition:0.3s;
    background-color:#2d2d2d;
    text-transform:uppercase;
    
  }
  
  .home-main-hero button:hover {
    transition:0.5s ease-in;
    background-color:#626161;
  }
  

  

  .home-main-text-1 {

margin-top:5%;
padding:2%;
margin-bottom:5%;
display:grid;
justify-content:center;
justify-items:center;
border-radius:8px;
background-color: #ffffff3b;
  }

  .home-main-text-1 > p {
    text-align:center;
    padding:2px;
    font-weight:550;
    color:var(--main-font-color);
  }

    
  .home-main-container {
    min-height: 650px;
    background-color:var(--container-color);
    border-radius:2px;
    padding-top: 80px;
    
  }


  
  .cards-container {
    max-width: 1550px;
    margin: 0 auto;
    padding: 50px 0px;
    display:flex;
    flex-wrap: wrap;
    justify-content:center;
    min-height: 50vh;
  }

 .cards {
    min-width:350px;
    max-width:420px;   
    align-items: center;
    margin: 15px 15px 0px 15px;
    color:var(--main-font-color);
    background-color: var(--cardsColor);
    border-radius: 7px;
    border-bottom-left-radius:0;
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    flex-basis: calc(33.33% - 20px);
    flex-grow: 1;
    height: max-content;
    height: 600px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    transition:0.1s;
  }
  
.cards:hover{
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
transition:0.2s;
}

  .cards img {
    width:100%;
    aspect-ratio: 1 / 1;
    overflow: hidden;
    object-fit: cover;
    object-position: center;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    /* min-height:410px; */
  }
  
  .cards-textbox {
    width:100%;
    padding:10px;
    
  }

  .cards h3 {
    margin-top: 0px;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    letter-spacing: -1.5px;
    padding:5px;
  }
  
  .cards p {
    margin: 10px auto 10px auto;
    font-size: 16px;
    text-align: left;
    line-height: 1.5;
  }

  .cards ul {
    display: inline-block;
    list-style: none;
    margin: auto;
    padding: 0;
    max-height: 75px; /* Set your desired max-height */
    overflow: hidden;
  }
  
  .cards li {
    background-color: #80808021;
    border-radius: 5px;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 5px 10px;
  }
  
  .cards li:last-child {
    margin-right: 0;
  }
  
  .cards::after {
    content: ''; /* Clearfix to ensure proper layout */
    display: block;
    clear: both;
  }
  

.read-more {
width:50%;
display: block;
margin:10px auto 10px auto;
border-radius:20px;
border: 1px solid var(--main-font-color);
padding:2px;
color:var(--main-font-color);
transition:0.2s ease-in;
}

.read-more:hover {
background-color:#00000026;
transition:0.2s ease-in;
  }

  .price {
    font-weight:700;
    background-color: grey;
    color:white;
    padding: 5px 12px 5px 12px;
    width:fit-content;
    text-transform:uppercase;
    
  }

  .blogpic {
    width:96%;
    height:90vh;
    overflow:hidden;
    object-fit:cover;
    padding:2%;
    border:solid;
    border-color:#ffffff3d;
    margin:2%;
  }

  @media screen and (max-width: 800px) {
     .cards {
      min-width:300px;
      max-width:90%;
      height: 550px;
    }

  }

  .blog-image-wrapper {
    position: relative;
    text-align: center;
    height: 600px;
  }

.home-main-container-blog {
  margin-top:10%;

}

  .blog-over-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    padding: 2% 10% 2% 10%;
    font-weight:600;
    font-size: 4em; 
    color: white; 
    border:solid;
    transition:1s;
    background-image:var(--pattern);
  }

  .home-main-blog-pic {
    display: block;
    width: 100%;
    height: 600px;
    overflow:hidden;
    object-fit:cover;
    filter:blur(2px);
    opacity:0.7;
  }

  .blog-icon {
   color:transparent;
   transition:1s;
   
  }

  .blog-over-title:hover .blog-icon {
    color: white;
    transition:1s;
  }