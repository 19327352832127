#faq {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    padding-bottom:120px;
  }

  .faq-title {
    width:100%;
    padding:12px;
    padding-left:1rem;
    background-image: var(--pattern);
    color:var(--main-font-color);
    font-size:32px; 
    text-transform:uppercase;
  }

  .faq-title h1 {
    width:100%;
    padding:12px;
    max-width:1200px;
  }

  .link-to-patreon a:hover {
    opacity:0.9;
    transition:0.5s;
  }


  .link-to-patreon {
    width: 100%;

    display: flex;
    flex-direction: column;

    

  }
  
  .link-to-patreon a {
    margin-bottom: 60px;
    width: 100%;
    height: 180px;

    display: flex;
    justify-content: center;

    color: #052d49;
    background-color: #f96854;
  }
  
  .link-to-patreon a div {
    margin: auto;
  }
  
  .link-to-patreon a:hover div h2 .arrow-link{
    opacity:1;
    transition:1s;
  }
  .arrow-link {
    opacity:0;
    transition:1s;
  }
  

  .link-to-patreon a div h1,
  .link-to-patreon a div h2 {
    position: relative;
    font-weight: 700;
    font-size: calc(1vw + 24px);
    transition: 0.5s;
    width:max-content;
  }
  
  .link-to-patreon a div h2 {
    font-weight: 500;
    font-size: 18px;
  }
  
  .link-to-patreon a div h1::after,
  .link-to-patreon a div h2::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 3px; /* Height of the underline */
    background-color: #052d49;
    transition: width 0.5s ease-in-out;
  }
  
  .link-to-patreon a div h1:hover::after,
  .link-to-patreon a div h2:hover::after {
    width: 100%;
  }
  
  .link-to-patreon a:hover div h1::after,
  .link-to-patreon a:hover div h2::after {
    width: 100%;
  }
  
  



/* FAQ Section */
#faq {
  margin: auto;
  text-align: left;
  color: var(--main-font-color);
}

.faq-item {
  border-bottom: 1px solid var(--main-font-color);
  padding: 15px 0;
}

.faq-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}

.faq-header-wrapper h2 {
  font-size: 16px;
  margin: 0;
}

.faq-header-wrapper:hover {
  color: #007bff;
}

.icon-active {
  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out;
}

.faq-wrapper {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.4s ease-in-out, opacity 0.4s ease-in-out;
  opacity: 0;
}

.faq-wrapper.open {
  max-height: 600px;
  overflow:scroll;
  opacity: 1;
  
}

.faq-wrapper p {
  margin:10px 0 10px 0;
}

.faq-wrapper strong {
  font-weight: bold;
}

.faq-wrapper a {
  color: #007bff;
  text-decoration: none;
}

.faq-wrapper a:hover {
  text-decoration: underline;
}



.advert {
  max-width:1200px;
  margin:auto;
}

.ad-sec {
  color:var(--container-color);
  background-color:var(--main-font-color);
  padding:12px;
  margin-bottom:6px;
}

.ad-sec h3{
  font-size: 16px;
  margin: 0;
  font-weight:700;
}

.ad-sec p{
  margin:6px 0 10px 0;
}


.ps {
  margin:auto;
  text-align:center;
  padding-bottom:120px;
}

.ps p {
  margin:12px;
  padding:12px;
}

.telefonbolki {
  background-image: url('./telefonbolki.png');
  background-size: cover;
  background-position: center;
  height: 400px;
  display: flex;
}

.title-text-container-onlineyoga {
  width: 100%; /* Ensure it spans the full width */
  height: 100%; /* Ensure it spans the full height */
  position: relative; /* Needed for the absolute positioning of child elements */
}

.title-text-container-onlineyoga h1 {
  color: transparent; /* Makes the fill of the text transparent */
  -webkit-text-stroke: 1px #f96854; /* Defines the thickness and color of the outline */
  text-stroke: 1px #f96854; /* For browsers that support text-stroke */
  font-size: calc(32px + 4vw);
  font-weight: 800;
  position: absolute; /* Allow precise positioning */
  top: 10%; /* Align to the top */
  left: 12%; /* Align to the left */
  margin: 10px; 
  text-transform: uppercase;
 
}

.title-text-container-onlineyoga h2 {
  color:#343435;
  font-weight: 800;
  font-size: calc(18px + 4vw);
  text-transform: uppercase;
  position: absolute; /* Allow precise positioning */
  bottom: 5%; /* Align to the bottom */
  right: 5%; /* Align to the right */
  margin: 10px; /* Optional: add some spacing from the edges */
}

.join-section {

  display:flex;
  width:100%;
  justify-content:center;
  padding-bottom:120px;
}

.join-button {
  margin:auto;
  padding:12px;
  background-color: #f96854;
  color: #052d49;
  font-weight:700;
  transition:0.5s;
  border-radius:2px;
}

.join-button:hover {
opacity:0.8;
}